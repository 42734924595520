import React, { useEffect, useState, useRef } from "react";
import {
  Paper,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tabs,
  Tab,
  Modal,
  Button,
  Box,
  Pagination,
  TextField,
  CardHeader,
  IconButton,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import Divider from "@mui/material/Divider";
import OurLayouts from "../Layouts";
import apiService from "../../services/apiservice";
import { FaEye } from "react-icons/fa";
import { Sidebar } from "primereact/sidebar";
import AddEnquiry from "./addenquiry";
import AssignEnquiry from "./assign";
import AddService from "./addService";
import FilterEnquiry from "./filterEnquiry";
import moment from "moment";
import * as XLSX from "xlsx";
import PlusICon from "../../assets/images/PlusIcon.svg";
import { FaFilter } from "react-icons/fa";
import { TiExport } from "react-icons/ti";
import { IoPrintOutline } from "react-icons/io5";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import { InputText } from "primereact/inputtext";
import { Dropdown } from "primereact/dropdown";
import { toast } from "react-toastify";
import { Calendar } from "primereact/calendar";
import ServiceEnquiry from "./serviceEnquriyform";

const ITEMS_PER_PAGE = 10;

const EnquiryList = () => {
  var currentDate = new Date();
  const userObj = JSON.parse(localStorage.getItem("loginObj"));
  const roleCode = localStorage.getItem("userRole");

  let location;
  if (roleCode === "PRADMIN") {
    location = JSON.parse(localStorage.getItem("location"));
  }
  const [loading, setloading] = useState(false);

  const user = userObj.user;
  const [enquiryDetails, setEnquiryDetails] = useState([]);
  const [filterObj, setFilterObj] = useState();
  const [viewEnquiry, setViewEnquiry] = useState(false);
  const [paginatedEnquiryDetails, setPaginatedEnquiryDetails] = useState([]);
  const [activeTab, setActiveTab] = useState(0);
  const [selectedRow, setselectedRow] = useState([]);
  const [newDetails, setNewDetails] = useState([]);
  const [exportObj, setexportObj] = useState();
  const [billingMode, setBillingMode] = useState("");
  const childRef = useRef();
  const [fromDateforAll, setFromDateForAll] = useState(
    new Date(currentDate.getFullYear(), currentDate.getMonth(), 1)
  );
  const [toDateforAll, setToDateForAll] = useState(
    new Date(currentDate.getFullYear(), currentDate.getMonth() + 1, 0)
  );
  let newTab = activeTab;
  const [viewServiceEnquiry, setviewServiceEnquiry] = useState(false);

  const [userType, setUsertype] = useState("");
  const [enquiryId , setenquiryId] = useState(null);
  const [mode, setMode] = useState("");
  let page = 1;

  const [page1, setPage] = useState(1);

  const handleChange = (newValue) => {
    setFilterObj({});
    page = 1;
    setPage(1);
    setEnquiryDetails([]);
    setPaginatedEnquiryDetails([]);
    setActiveTab(newValue);
    if (newValue != 2) {
      newTab = newValue;
      gettabEnqList(newValue);
    } else {
      newTab = newValue;

      getEnqList();
    }
  };

  const statusList = [
    { id: 0, value: "Rejected" },
    { id: 1, value: "Under Process" },
    { id: 4, value: "Patient Denied" },
    { id: 5, value: "Hospital Denied" },
    { id: 6, value: "Procedure Confirmed" },
    { id: 12, value: "Waiting For Approval" },
    { id: 7, value: "Hold" },
  ];

  const handlePageChange = (event, value) => {
    page = value;
    setPage(value);
    setEnquiryDetails([]);
    setPaginatedEnquiryDetails([]);
    getEnqList();
  };

  const getbillingStyle = (e) => {
    let payload = {
      color:
        e == 1
          ? "#ECAC09"
          : e == 2
          ? "violet"
          : e == 3
          ? "#7225EF"
          : e == 4
          ? "#299F5D"
          : e == 5
          ? "#DD0000"
          : "#3D3BA4",
    };
    return payload;
  };

  const filterCancel = (filter) => {
    page = 1;
    setPage(1);
    setViewEnquiry(false);
    setFilterObj(filter);
    getEnqList(filter);
  };

  const convertUTCDate = (date, format) => {
    format = format || "MM/DD/YYYY";
    const localTime = moment.utc(date).toDate();
    return moment(localTime).format(format);
  };

  const convertUTCToLocal = (date, format) => {
    if (!format) {
      format = "MM-DD-YYYY, HH:mm:ss";
    }
    const localDate = moment.utc(date).local();
    return localDate.format(format);
  };

  const handleCancel = async (e) => {
    setViewEnquiry(false);
    if (activeTab == 2 || activeTab == 0) {
      getEnqList();
    } else {
      gettabEnqList(activeTab);
    }
  };

  const handleCancelView1 = async (e) => {
    setBillingMode("");
    setBillingView(false);
  };

  const handleCancelView = async (e) => {
    if (childRef.current) {
      const childValue = childRef.current.getValue();
      console.log(childValue);
      if (childValue.billingStatus == 0 && childValue.amount) {
        return toast.warn("Please get Approval from Admin");
      }
    }
    if (activeTab == 0) {
      getEnqList();
    }
    setViewEnquiry(false);
  };
  function getFirstDayOfMonth() {
    const now = new Date();
    return new Date(now.getFullYear(), now.getMonth(), 1);
}

function getLastDayOfMonth() {
    const now = new Date();
    return new Date(now.getFullYear(), now.getMonth() + 1, 0);
}


const getEnqList = async (data) => {
  let filter = data ? data : filterObj ? filterObj : null;
  let payload = {};
  try {
      if (filter) {
        console.log(filter,"AllFilters");
          if (filter.status || filter.status == 0) {
              payload.status = filter.status;
          }
          if (filter.patientId) {
              payload.patientId = filter.patientId;
          }
          if (filter.fromDate) {
              payload.fromDate = convertUTCDate(filter.fromDate);
          }
          if (filter.toDate) {
              payload.toDate = convertUTCDate(filter.toDate);
          }
          if (filter.fromDate1) {
              payload.fromDate1 = convertUTCDate(filter.fromDate1);
          }
          if (filter.toDate1) {
              payload.toDate1 = convertUTCDate(filter.toDate1);
          }
          console.log(filter?.campLead,"CAMPLEAD");
          if (filter.campLead !== undefined && filter.campLead !== null) {
            payload.campLead = filter.campLead;
        }
          if (filter.procedurePlannedDate) {
              payload.procedurePlannedDate = convertUTCDate(filter.procedurePlannedDate);
          }
          if (filter.serviceType) {
              payload.serviceType = filter.serviceType;
          }
          if (filter.serviceType) {
              payload.serviceType = filter.serviceType;
          }
          if (filter.preferredHospitalAndClinics) {
              payload.locationId = filter.preferredHospitalAndClinics;
          }
          if (filter.conciergeUserCode) {
              payload.conciergeUserCode = filter.conciergeUserCode;
          }
          if (filter.conciergeStatus) {
              payload.conciergeStatus = filter.conciergeStatus;
          }
          if (filter.serviceEnquiryId) {
              payload.id = filter.serviceEnquiryId;
          }
        
          if (newTab == 1) {
              if (roleCode != "CE") {
                  payload.assignedUser = user.code;
              } else {
                  payload.conciergeUserCode = user.code;
              }
              payload.roleCode = roleCode;
          }
      } else {
          if (activeTab == 2 || newTab == 2) {
              // payload.fromDate = convertUTCDate(fromDateforAll);
              // payload.toDate = convertUTCDate(toDateforAll);
          } else {
              payload.status = 11;
          }
      }

      if (!payload.fromDate &&(!payload.fromDate1 && !payload.toDate1)) {
          payload.fromDate = convertUTCDate(getFirstDayOfMonth());
      }

      if (!payload.toDate &&(!payload.fromDate1 && !payload.toDate1)) {
          payload.toDate = convertUTCDate(getLastDayOfMonth());
      }

     

      payload.pageNumber = page;
      setloading(true);
      setexportObj(payload);
      if (activeTab == 0 && newTab == 0) {
          payload.roleCode = roleCode;
      }
      const uploadlabOrderReportResponse =
          activeTab == 0 && newTab == 0
              ? await apiService.getenqCElist(payload)
              : await apiService.getenqList(payload);
      if (!uploadlabOrderReportResponse.message) {
          let data = [];
          if (newTab == 0) {
              uploadlabOrderReportResponse.data.forEach((element) => {
                  data.push(element);
              });
              setPaginatedEnquiryDetails(data);
              setloading(false);
          } else {
              uploadlabOrderReportResponse.data.forEach((element) => {
                  data.push(element);
              });
              setPaginatedEnquiryDetails(data);
              setloading(false);
          }
      }
  } catch (error) {
      console.error("Error fetching enquiry list:", error);
      setloading(false);
  }
};


  const handleChangefilter = (e) => {
    setFilterObj((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value,
    }));
  };

  const exportToexcel = async () => {
    let exporrtExcel = [];
    let excel = [];
    let payload = exportObj;
    payload.pageNumber = -1;
    if (!payload.fromDate && !payload.toDate && !payload.fromDate1 && !payload.toDate1) {
      payload.fromDate = convertUTCDate(currentDate.setDate(1));
      payload.toDate = convertUTCDate(new Date());
    }
    if (activeTab == 0) {
      payload.roleCode = roleCode;
    }
    const res =
      activeTab == 0
        ? await apiService.exportNewEenqList(payload)
        : await apiService.expenqList(payload);

    let headers = [
      "EnquiryId",
      "PatientId",
      "PatientName",
      "Enquiry Date",
      "Eclinic Name",
      "Eclinic Location",
      "Email Id",
      "Swasthya Mithra",
      "Mobile Number",
      "Communication Number",
      "Preferred Hospital & eClinic",
      "Hospitalization Type",
      "Hospitalization Subtype",
      "Conceirge Name",
      "Conceirge Status",
      "Status",
      "Reason For Hold",
      "Reason For Reject",
      "Camp Lead",
    ];

    exporrtExcel.push(headers);
    for (let i = 0; i < res.data.length; i++) {
      excel = [
        res.data[i].id,
        res.data[i].patientId,
        res.data[i].name,
        res.data[i].createdDate,
        res.data[i].chehospitalName,
        res.data[i].chelocationName,
        res.data[i]?.emailId || "",
        res.data[i].swasthUserName ? res.data[i].swasthUserName : "NA",
        res.data[i].contactNumber ? res.data[i].contactNumber : "",
        res.data[i].communicationMobile ? res.data[i].communicationMobile : "",
        res.data[i].hospitalName,
        res.data[i].hospitalizationType,
        res.data[i].procedureName,
        res.data[i].conciergeName,
        res.data[i].conciergeStatus == 1
          ? "Patient Admitted"
          : res.data[i].conciergeStatus == 2
          ? "Patient Denied"
          : res.data[i].conciergeStatus == 3
          ? "Patient iN OT"
          : res.data[i].conciergeStatus == 4
          ? "Patient Discharged"
          : "",
        res.data[i].status == 0
          ? "Rejected"
          : res.data[i].status == 1
          ? "Under Process"
          : res.data[i].status == 6
          ? "Procedure Confirmed"
          : res.data[i].status == 5
          ? "Hospital Denied"
          : res.data[i].status == 2
          ? "Discussing with Hospital"
          : res.data[i].status == 3
          ? "Discussing with Patient"
          : res.data[i].status == 4
          ? "Patient Denied"
          : res.data[i].status == 12
          ? "Waiting For Approval"
          : res.data[i].status == 7
          ? "Hold"
          : res.data[i].status == 11
          ? "New"
          : "",
        res.data[i].reasonForHold,
        res.data[i].reasonForReject,
        res.data[i]. campLead == 0 ? 'NO': res.data[i]. campLead == 1? 'YES':''
      ];
      exporrtExcel.push(excel);
    }

    const wb = XLSX.utils.book_new();
    const ws = XLSX.utils.aoa_to_sheet(exporrtExcel);
    XLSX.utils.book_append_sheet(wb, ws, "Sheet1");
    XLSX.writeFile(wb, "exported_data.xlsx");
  };

  useEffect(() => {
    getEnqList();
  }, [fromDateforAll, toDateforAll]);

  const handleClick = (data, mode) => {
    if (
      (data.billingStatus == 2 && mode == "Add") ||
      (mode == "Add" && roleCode == "ACCADMIN")
    ) {
      return;
    }
    setMode(mode);
    setselectedRow(data);
    setViewEnquiry(true);
  };
  const handleClickEnquiry = (id) => {
    setviewServiceEnquiry(true);
    setenquiryId(id)
  };

  const handleClick1 = (data, mode, type) => {
    if (
      (data.billingStatus == 2 && mode == "Add") ||
      (mode == "Add" && roleCode == "ACCADMIN")
    ) {
      return;
    }
    setMode(mode);
    setUsertype(type);
    setselectedRow(data);
    setViewEnquiry(true);
  };

  const printClick = () => {
    window.print();
  };

  const toggleSidebar = () => {
    setViewEnquiry(!viewEnquiry);
  };
  const toggleserviceSideBar = () => {
    setviewServiceEnquiry(!viewServiceEnquiry);
  };

  const gettabEnqList = async (newValue) => {
    let payload = {};
    if (newValue == 0) {
      payload.status = 11;
    }
    if (newValue == 1) {
      if (roleCode != "CE") {
        payload.assignedUser = user.code;
      } else {
        payload.conciergeUserCode = user.code;
      }
      payload.roleCode = roleCode;
    }
    payload.pageNumber = page;
    if(filterObj && (filterObj?.status || filterObj.status == 0)) {
      payload.status = filterObj.status;
    }
    setexportObj(payload);
    setloading(true);
    if (newValue == 0) {
      payload.roleCode = roleCode;
    }
  //   if (filterObj.fromDate) {
  //     payload.fromDate = convertUTCDate(filter.fromDate);
  // }
  // if (filter.toDate) {
  //     payload.toDate = convertUTCDate(filter.toDate);
  // }
    if (!payload.fromDate) {
      payload.fromDate = convertUTCDate(getFirstDayOfMonth());
  }

  if (!payload.toDate) {
      payload.toDate = convertUTCDate(getLastDayOfMonth());
  }
    try {
      const uploadlabOrderReportResponse =
        newValue == 0
          ? await apiService.getenqCElist(payload)
          : await apiService.getenqList(payload);
      if (!uploadlabOrderReportResponse.message) {
        setPaginatedEnquiryDetails(uploadlabOrderReportResponse.data);
        setloading(false);
      }
    } catch (error) {
      console.error("Error fetching enquiry list:", error);
    }
  };

  const getHeader = () => {
    return (
      <div className="font-poppins text-primary text-xl font-bold leading-6 tracking-normal text-left">
        {mode === "View"
          ? "View Service Enquiry Details"
          : mode === "Assign"
          ? "Assign Enquiry"
          : mode === "Filter"
          ? "Filter Enquiry"
          : "Add Service"}
      </div>
    );
  };

  const getStylestatus = (e) => {
    let payload = {
      border: "1px solid",
      borderColor:
        e == 1 || e == 2 || e == 3 || e == 7
          ? "#ECAC09"
          : e == 0 || e == 4 || e == 5
          ? "#DD0000"
          : e == 6 || e == 8 || e == 9
          ? "#299F5D"
          : e == 11
          ? "#3D3BA4"
          : "black",
      padding: "5px",
      color:
        e == 1 || e == 2 || e == 3 || e == 7
          ? "#ECAC09"
          : e == 0 || e == 4 || e == 5
          ? "#DD0000"
          : e == 6 || e == 8 || e == 9
          ? "#299F5D"
          : e == 11
          ? "#3D3BA4"
          : "black",
      display: "inline-block",
      borderRadius: "5px",
    };
    return payload;
  };

  const getconceirgeStyle = (e) => {
    let payload = {
      color:
        e == 1
          ? "#3D3BA4"
          : e == 2
          ? "#DD0000"
          : e == 3
          ? "#ECAC09"
          : e == 4
          ? "#299F5D"
          : "#3D3BA4",
    };
    return payload;
  };

  const [billingView, setBillingView] = useState(false);
  const [billingData, setBillingData] = useState();

  const onCountView = async (data) => {
    let payload = {
      serviceEnquiryId: data?.id ? data?.id : selectedRow.id,
      allBillingFlag: 1,
    };
    const res = await apiService.getServicesBillList(payload);
    if (!res.data || res.data.length == 0) {
      return toast("No data found");
    } else {
      setBillingData(res.data);
      if (data?.id) {
        setBillingView(true);
        setselectedRow(data);
      }
      setBillingMode("Edit");
    }
  };

  const cancel = () => {
    onCountView();
  };

  return (
    <>
      <OurLayouts>
        <Backdrop
          sx={{ color: "#FFFFFF", zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={loading}
        >
          <CircularProgress size={80} color="inherit" />
        </Backdrop>
        <div>
          <Button icon="pi pi-bars" onClick={toggleSidebar} />
          <Sidebar
            style={{ width: "60%", borderBottom: "1px solid #ccc" }}
            dismissable={false}
            visible={viewEnquiry}
            header={getHeader()}
            centered={true}
            onHide={() => handleCancelView()}
            position="right"
          >
            <Sidebar
              style={{ width: "60%", borderBottom: "1px solid #ccc" }}
              dismissable={false}
              visible={viewServiceEnquiry}
              header={getHeader()}
              centered={true}
              onHide={() => handleCancelView()}
              position="right"
            ></Sidebar>
            {mode == "View" && (
              <div style={{ padding: "1rem" }}>
                <AddEnquiry selected={selectedRow} onClose={handleCancelView} />
              </div>
            )}
            {mode == "Assign" && (
              <div style={{ padding: "1rem" }}>
                <AssignEnquiry
                  selected={selectedRow}
                  userType={userType}
                  onClose={handleCancel}
                />
              </div>
            )}
            {mode == "Add" && (
              <div style={{ padding: "1rem" }}>
                <AddService
                  selected={selectedRow}
                  mode={billingMode}
                  ref={childRef}
                  onClose={handleCancel}
                />
              </div>
            )}
            {mode === "Filter" && (
              <div style={{ padding: "1rem" }}>
                <FilterEnquiry
                  onCance={handleCancel}
                  filterObj={filterObj}
                  mode={
                    activeTab == 0 ? "New" : activeTab == 1 ? "Assign" : "All"
                  }
                  onClose={(e) => filterCancel(e)}
                />
              </div>
            )}
          </Sidebar>
          <Sidebar
            visible={viewServiceEnquiry}
            onHide={() => setviewServiceEnquiry(false)}
            style={{ width: "60%" }}
            position="right"
          >
            <ServiceEnquiry enquiryId={enquiryId}/>
          </Sidebar>
        </div>
        <>
          <div className="flex flex-col w-full mt-[7rem] overflow-auto">
            <div className="flex justify-between">
              <h1 className="text-2xl text-primary font-semibold mb-8">
                Service Enquiry List
              </h1>
              <div className="flex border h-10 rounded-md pt-1 pb-1 mr-12 justify-items-center">
                <div className="flex mx-2 border-r-2">
                  <Button
                    className="flex gap-1"
                    onClick={(e) => handleClick({}, "Filter")}
                  >
                    <FaFilter className="text-primary" />
                    <h6 className="text-primary">Filter</h6>
                  </Button>
                </div>
                <div className="flex mx-2 border-r-2">
                  <Button
                    className="flex gap-1"
                    onClick={() => exportToexcel()}
                  >
                    <TiExport className="text-primary text-2xl" />
                    <h6 className="text-primary">Export Excel</h6>
                  </Button>
                </div>
                {/* <div className="flex mx-2">
                  <Button className="flex gap-1">
                    <IoPrintOutline className="text-primary text-2xl" />
                    <h6 className="text-primary">Print</h6>
                  </Button>
                </div> */}
              </div>
            </div>
            <div className="flex items-center mt-4 gap-2 mr-10">
              {activeTab != 0 && (
                <div className="ml-3 flex gap-4">
                  <div>
                    <Dropdown
                      optionLabel="value"
                      placeholder="Select Status"
                      options={statusList}
                      name="status"
                      value={filterObj?.status}
                      optionValue="id"
                      className="border border-gray-300 rounded-md w-full"
                      onChange={(e) => handleChangefilter(e)}
                    />
                  </div>
                  <div>
                    <button
                      className="w-32 h-9 bg-[#004172] text-white rounded-full"
                      onClick={handleCancel}
                    >
                      Search
                    </button>
                  </div>
                </div>
              )}
            </div>
            <div
              className="w-[99%] mt-8 rounded-[5px]"
              style={{ boxShadow: "0px 8px 25px 0px rgba(0, 0, 0, 0.06)" }}
            >
              <Tabs
                value={activeTab}
                onChange={(event, newValue) => handleChange(newValue)}
              >
                <Tab label={<span style={{ fontWeight: "bold" }}>New</span>} />
                <Tab
                  disabled={roleCode != "CE" && roleCode != "GROUPPRADMIN"}
                  label={
                    <span style={{ fontWeight: "bold" }}>
                      {roleCode != "CE" && roleCode != "GROUPPRADMIN"
                        ? ""
                        : "Assign To Me"}
                    </span>
                  }
                />
                <Tab label={<span style={{ fontWeight: "bold" }}>ALL</span>} />
              </Tabs>
              <div className="w-full">
                {activeTab == 2 && (
                  <TableContainer
                    component={Paper}
                    className="print-table"
                    style={{ width: "100%", overflowX: "scroll" }}
                  >
                    
                    <Table>
                      <TableHead>
                        <TableRow>
                          <TableCell
                            style={{ fontSize: "17px", fontWeight: 600 }}
                          >
                            Enquiry ID
                          </TableCell>
                          <TableCell
                            style={{ fontSize: "17px", fontWeight: 600 }}
                          >
                            Patient ID
                          </TableCell>
                          <TableCell
                            style={{ fontSize: "17px", fontWeight: 600 }}
                          >
                            Patient Name
                          </TableCell>
                          <TableCell
                            style={{ fontSize: "17px", fontWeight: 600 }}
                          >
                            Enquiry Date
                          </TableCell>
                          <TableCell
                            style={{ fontSize: "17px", fontWeight: 600 }}
                          >
                            Eclinic Name
                          </TableCell>
                          <TableCell
                            style={{ fontSize: "17px", fontWeight: 600 }}
                          >
                            Eclinic Location
                          </TableCell>
                          <TableCell
                            style={{ fontSize: "17px", fontWeight: 600 }}
                          >
                            Email Id
                          </TableCell>
                          <TableCell
                            style={{ fontSize: "17px", fontWeight: 600 }}
                          >
                            Swasthya Mithra
                          </TableCell>
                          <TableCell
                            style={{ fontSize: "17px", fontWeight: 600 }}
                          >
                            Mobile Number
                          </TableCell>
                          <TableCell
                            style={{ fontSize: "17px", fontWeight: 600 }}
                          >
                            Communication Number
                          </TableCell>
                          <TableCell
                            style={{ fontSize: "17px", fontWeight: 600 }}
                          >
                            Preferred Hospital & eClinic
                          </TableCell>
                          <TableCell
                            style={{ fontSize: "17px", fontWeight: 600 }}
                          >
                            Concierge Name
                          </TableCell>
                          <TableCell
                            style={{ fontSize: "17px", fontWeight: 600 }}
                          >
                            Concierge Status
                          </TableCell>
                          <TableCell
                            style={{
                              fontSize: "17px",
                              fontWeight: 600,
                              width: "200px",
                            }}
                          >
                            Status
                          </TableCell>
                          <TableCell
                            style={{ fontSize: "17px", fontWeight: 600 }}
                          >
                            Reason For Hold
                          </TableCell>
                          <TableCell
                            style={{ fontSize: "17px", fontWeight: 600 }}
                          >
                            Reason For Reject
                          </TableCell>
                          <TableCell
                            style={{ fontSize: "17px", fontWeight: 600 }}
                          >
                            Status Date
                          </TableCell>
                          <TableCell
                            style={{ fontSize: "17px", fontWeight: 600 }}
                          >
                            View Enquiry Details
                          </TableCell>
                          <TableCell
                            style={{ fontSize: "17px", fontWeight: 600 }}
                          >
                            Billing Count
                          </TableCell>
                          
                          <TableCell
                            style={{
                              fontSize: "17px",
                              fontWeight: 600,
                              cursor: "pointer",
                            }}
                          >
                            Enquiry Log
                          </TableCell>
                          <TableCell
                            style={{
                              fontSize: "17px",
                              fontWeight: 600,
                              cursor: "pointer",
                            }}
                          >
                            Camp 
                          </TableCell>
                          <TableCell
                            style={{ fontSize: "17px", fontWeight: 600 }}
                          >
                            Action
                          </TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {paginatedEnquiryDetails.map((notifi) => (
                          <TableRow key={notifi.id}>
                            <TableCell
                              style={{ fontSize: "17px", fontWeight: 500 }}
                            >
                              {notifi.id}
                            </TableCell>
                            <TableCell
                              style={{ fontSize: "17px", fontWeight: 500 }}
                            >
                              {notifi.patientId}
                            </TableCell>
                            <TableCell
                              style={{ fontSize: "15px", fontWeight: 500 }}
                            >
                              {notifi.name}
                            </TableCell>
                            <TableCell
                              style={{ fontSize: "15px", fontWeight: 500 }}
                            >
                              {convertUTCToLocal(notifi.createdDate)}
                            </TableCell>
                            <TableCell
                              style={{ fontSize: "15px", fontWeight: 500 }}
                            >
                              {notifi.chehospitalName || ""}
                            </TableCell>
                            <TableCell
                              style={{ fontSize: "15px", fontWeight: 500 }}
                            >
                              {notifi.chelocationName || ""}
                            </TableCell>
                            <TableCell
                              style={{ fontSize: "15px", fontWeight: 500 }}
                            >
                              {notifi.emailId}
                            </TableCell>
                            <TableCell
                              style={{ fontSize: "15px", fontWeight: 500 }}
                            >
                              {notifi.swasthUserName
                                ? notifi.swasthUserName
                                : "NA"}
                            </TableCell>
                            <TableCell
                              style={{ fontSize: "15px", fontWeight: 500 }}
                            >
                              {notifi.contactNumber ? notifi.contactNumber : ""}
                            </TableCell>
                            <TableCell
                              style={{ fontSize: "15px", fontWeight: 500 }}
                            >
                              {notifi.communicationMobile
                                ? notifi.communicationMobile
                                : ""}
                            </TableCell>
                            <TableCell
                              style={{ fontSize: "15px", fontWeight: 500 }}
                            >
                              {notifi.hospitalName}
                            </TableCell>
                            <TableCell
                              style={{ fontSize: "15px", fontWeight: 500 }}
                            >
                              {notifi.conciergeName || ""}
                            </TableCell>
                            <TableCell
                              style={{ fontSize: "15px", fontWeight: 500 }}
                            >
                              <span
                                style={getconceirgeStyle(
                                  notifi.conciergeStatus
                                )}
                              >
                                {notifi.conciergeStatus == 1
                                  ? "Patient Admitted"
                                  : notifi.conciergeStatus == 2
                                  ? "Patient Denied"
                                  : notifi.conciergeStatus == 3
                                  ? "Patient iN OT"
                                  : notifi.conciergeStatus == 4
                                  ? "Patient Discharged"
                                  : ""}
                              </span>
                            </TableCell>
                            <TableCell
                              style={{
                                fontSize: "15px",
                                fontWeight: 500,
                                width: "200px",
                              }}
                              size="large"
                            >
                              {" "}
                              <span style={getStylestatus(notifi.status)}>
                                {notifi.status == 0
                                  ? "Rejected"
                                  : notifi.status == 1
                                  ? "Under Process"
                                  : notifi.status == 6
                                  ? "Procedure Confirmed"
                                  : notifi.status == 5
                                  ? "Hospital Denied"
                                  : notifi.status == 2
                                  ? "Discussing with Hospital"
                                  : notifi.status == 3
                                  ? "Discussing with Patient"
                                  : notifi.status == 4
                                  ? "Patient Denied"
                                  : notifi.status == 12
                                  ? "Waiting For Approval"
                                  : notifi.status == 7
                                  ? "Hold"
                                  : notifi.status == 11
                                  ? "New"
                                  : ""}
                              </span>
                            </TableCell>
                            {notifi.status == 7 ? (
                              <TableCell
                                style={{ fontSize: "15px", fontWeight: 500 }}
                              >
                                {notifi.reasonForHold}
                              </TableCell>
                            ) : (
                              <TableCell></TableCell>
                            )}
                            {notifi.status == 0 ? (
                              <TableCell
                                style={{ fontSize: "15px", fontWeight: 500 }}
                              >
                                {notifi.reasonForReject}
                              </TableCell>
                            ) : (
                              <TableCell></TableCell>
                            )}
                            <TableCell>
                              {notifi?.patientServiceEnquiryFormStatusLogList
                                ? convertUTCToLocal(
                                    notifi
                                      ?.patientServiceEnquiryFormStatusLogList[0]
                                      ?.statusDate
                                  )
                                : ""}
                            </TableCell>
                            <TableCell
                              style={{ fontSize: "15px", fontWeight: 500 }}
                            >
                              <FaEye
                                onClick={(e) => handleClick(notifi, "View")}
                              />
                            </TableCell>
                            <TableCell
                              style={{ fontSize: "15px", fontWeight: 500 }}
                            >

                              <FaEye
                                onClick={() => onCountView(notifi)}
                              />
                            </TableCell>
                            <TableCell
                              style={{
                                fontSize: "15px",
                                fontWeight: 500,
                                cursor: "pointer",
                              }}
                            >
                              <FaEye
                                onClick={(e) => handleClickEnquiry(notifi.id)}
                              />
                            </TableCell>
                            <TableCell
                              style={{
                                fontSize: "15px",
                                fontWeight: 500,
                                cursor: "pointer",
                              }}
                            >
                             {notifi?.campLead === 0 ? "No" : "Yes"}
                            </TableCell>
                            {notifi.status == 6 &&
                              notifi.conciergeStatus == 4 && (
                                <TableCell
                                  style={{
                                    fontSize: "15px",
                                    fontWeight: 500,
                                    width: "10%",
                                  }}
                                  onClick={(e) => handleClick(notifi, "Add")}
                                  disabled={notifi.billingStatus == 2}
                                >
                                  Add Service +
                                </TableCell>
                              )}
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                )}
                {activeTab == 0 && (
                  <TableContainer
                    component={Paper}
                    style={{ width: "100%", overflowX: "auto" }}
                  >
                    <Table>
                      <TableHead>
                        <TableRow>
                          <TableCell
                            style={{ fontSize: "17px", fontWeight: 600 }}
                          >
                            Enquiry ID
                          </TableCell>
                          <TableCell
                            style={{ fontSize: "17px", fontWeight: 600 }}
                          >
                            Patient ID
                          </TableCell>
                          <TableCell
                            style={{ fontSize: "17px", fontWeight: 600 }}
                          >
                            Patient Name
                          </TableCell>
                          <TableCell
                            style={{ fontSize: "17px", fontWeight: 600 }}
                          >
                            Enquiry Date
                          </TableCell>
                          <TableCell
                            style={{ fontSize: "17px", fontWeight: 600 }}
                          >
                            Eclinic Name
                          </TableCell>
                          <TableCell
                            style={{ fontSize: "17px", fontWeight: 600 }}
                          >
                            Eclinic Location
                          </TableCell>
                          <TableCell
                            style={{ fontSize: "17px", fontWeight: 600 }}
                          >
                            Email Id
                          </TableCell>
                          <TableCell
                            style={{ fontSize: "17px", fontWeight: 600 }}
                          >
                            Swasthya Mithra
                          </TableCell>
                          <TableCell
                            style={{ fontSize: "17px", fontWeight: 600 }}
                          >
                            Mobile Number
                          </TableCell>
                          <TableCell
                            style={{ fontSize: "17px", fontWeight: 600 }}
                          >
                            Communication Number
                          </TableCell>
                          <TableCell
                            style={{ fontSize: "17px", fontWeight: 600 }}
                          >
                            Preferred Hospital & eClinic
                          </TableCell>
                          <TableCell
                            style={{ fontSize: "17px", fontWeight: 600 }}
                          >
                            Concierge Name
                          </TableCell>
                          <TableCell
                            style={{ fontSize: "17px", fontWeight: 600 }}
                          >
                            Concierge Status
                          </TableCell>
                          <TableCell
                            style={{
                              fontSize: "17px",
                              fontWeight: 600,
                              width: "200px",
                            }}
                          >
                            Status
                          </TableCell>
                          <TableCell
                            style={{
                              fontSize: "17px",
                              fontWeight: 600,
                              width: "200px",
                            }}
                          >
                            Camp
                          </TableCell>
                          <TableCell
                            style={{ fontSize: "17px", fontWeight: 600 }}
                          >
                            Status Date
                          </TableCell>
                          <TableCell
                            style={{ fontSize: "17px", fontWeight: 600 }}
                          >
                            View Enquiry Details
                          </TableCell>
                          <TableCell
                            style={{ fontSize: "17px", fontWeight: 600 }}
                          >
                            Excecutive
                          </TableCell>
                          <TableCell
                            style={{ fontSize: "17px", fontWeight: 600 }}
                          >
                            Backoffice
                          </TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {paginatedEnquiryDetails.map((notifi) => (
                          <TableRow
                            key={notifi.id}
                            sx={{
                              backgroundColor:
                                notifi.viewStatus == 1 ? "lightblue" : "white",
                            }}
                          >
                            <TableCell
                              style={{ fontSize: "17px", fontWeight: 500 }}
                            >
                              {notifi.id}
                            </TableCell>
                            <TableCell
                              style={{ fontSize: "15px", fontWeight: 500 }}
                            >
                              {notifi.patientId}
                            </TableCell>
                            <TableCell
                              style={{ fontSize: "15px", fontWeight: 500 }}
                            >
                              {notifi.name}
                            </TableCell>
                            <TableCell
                              style={{ fontSize: "15px", fontWeight: 500 }}
                            >
                              {convertUTCToLocal(notifi.createdDate)}
                            </TableCell>
                            <TableCell
                              style={{ fontSize: "15px", fontWeight: 500 }}
                            >
                              {notifi.chehospitalName || ""}
                            </TableCell>
                            <TableCell
                              style={{ fontSize: "15px", fontWeight: 500 }}
                            >
                              {notifi.chelocationName}
                            </TableCell>
                            <TableCell
                              style={{ fontSize: "15px", fontWeight: 500 }}
                            >
                              {notifi.emailId}
                            </TableCell>
                            <TableCell
                              style={{ fontSize: "15px", fontWeight: 500 }}
                            >
                              {notifi.swasthUserName
                                ? notifi.swasthUserName
                                : "NA"}
                            </TableCell>
                            <TableCell
                              style={{ fontSize: "15px", fontWeight: 500 }}
                            >
                              {notifi.contactNumber ? notifi.contactNumber : ""}
                            </TableCell>
                            <TableCell
                              style={{ fontSize: "15px", fontWeight: 500 }}
                            >
                              {notifi.communicationMobile
                                ? notifi.communicationMobile
                                : ""}
                            </TableCell>
                            <TableCell
                              style={{ fontSize: "15px", fontWeight: 500 }}
                            >
                              {notifi.hospitalName}
                            </TableCell>
                            <TableCell
                              style={{ fontSize: "15px", fontWeight: 500 }}
                            >
                              {notifi.conciergeName || ""}
                            </TableCell>
                            <TableCell
                              style={{ fontSize: "15px", fontWeight: 500 }}
                            >
                              {notifi.conciergeStatus == 1
                                ? "Patient Admitted"
                                : notifi.conciergeStatus == 2
                                ? "Patient Denied"
                                : notifi.conciergeStatus == 3
                                ? "Patient iN OT"
                                : notifi.conciergeStatus == 4
                                ? "Patient Discharged"
                                : ""}
                            </TableCell>
                            <TableCell
                              style={{
                                fontSize: "15px",
                                fontWeight: 500,
                                width: "200px",
                              }}
                            >
                              {" "}
                              <span style={getStylestatus(notifi.status)}>
                                {notifi.status == 0
                                  ? "Rejected"
                                  : notifi.status == 1
                                  ? "Under Process"
                                  : notifi.status == 6
                                  ? "Procedure Confirmed"
                                  : notifi.status == 5
                                  ? "Hospital Denied"
                                  : notifi.status == 2
                                  ? "Discussing with Hospital"
                                  : notifi.status == 3
                                  ? "Discussing with Patient"
                                  : notifi.status == 4
                                  ? "Patient Denied"
                                  : notifi.status == 12
                                  ? "Waiting For Approval"
                                  : notifi.status == 7
                                  ? "Hold"
                                  : notifi.status == 11
                                  ? "New"
                                  : ""}
                              </span>
                            </TableCell>
                            <TableCell
                              style={{
                                fontSize: "15px",
                                fontWeight: 500,
                                width: "200px",
                              }}
                            >
                              {notifi?.campLead === 0 ? "No" : "Yes"}
                              
                            </TableCell>
                            <TableCell>
                              {notifi?.patientServiceEnquiryFormStatusLogList
                                ? convertUTCToLocal(
                                    notifi
                                      ?.patientServiceEnquiryFormStatusLogList[0]
                                      ?.statusDate
                                  )
                                : ""}
                            </TableCell>
                            <TableCell
                              style={{ fontSize: "15px", fontWeight: 500 }}
                            >
                              <FaEye
                                onClick={(e) => handleClick(notifi, "View")}
                              />
                            </TableCell>
                            {roleCode != "ACCADMIN" ? (
                              <TableCell>
                                <button
                                  className="w-36 h-9 bg-[#004172] text-white rounded-full"
                                  onClick={(e) =>
                                    handleClick1(notifi, "Assign", "CE")
                                  }
                                >
                                  Executive Assign
                                </button>
                              </TableCell>
                            ) : (
                              <TableCell></TableCell>
                            )}
                            {roleCode != "ACCADMIN" && roleCode != "CE" ? (
                              <TableCell>
                                <button
                                  className="w-36 h-9 bg-[#004172] text-white rounded-full"
                                  onClick={(e) =>
                                    handleClick1(notifi, "Assign", "BO")
                                  }
                                >
                                  Back Office Assign
                                </button>
                              </TableCell>
                            ) : (
                              <TableCell></TableCell>
                            )}
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                )}
                {activeTab == 1 && (
                  <TableContainer
                    component={Paper}
                    style={{ width: "100%", overflowX: "auto" }}
                  >
                    <Table>
                      <TableHead>
                        <TableRow>
                          <TableCell
                            style={{ fontSize: "17px", fontWeight: 600 }}
                          >
                            Enquiry ID
                          </TableCell>
                          <TableCell
                            style={{ fontSize: "17px", fontWeight: 600 }}
                          >
                            Patient ID
                          </TableCell>
                          <TableCell
                            style={{ fontSize: "17px", fontWeight: 600 }}
                          >
                            Patient Name
                          </TableCell>
                          <TableCell
                            style={{ fontSize: "17px", fontWeight: 600 }}
                          >
                            Enquiry Date
                          </TableCell>
                          <TableCell
                            style={{ fontSize: "17px", fontWeight: 600 }}
                          >
                            Eclinic Name
                          </TableCell>
                          <TableCell
                            style={{ fontSize: "17px", fontWeight: 600 }}
                          >
                            Eclinic Location
                          </TableCell>
                          <TableCell
                            style={{ fontSize: "17px", fontWeight: 600 }}
                          >
                            Email Id
                          </TableCell>
                          <TableCell
                            style={{ fontSize: "17px", fontWeight: 600 }}
                          >
                            Swasthya Mithra
                          </TableCell>
                          <TableCell
                            style={{ fontSize: "17px", fontWeight: 600 }}
                          >
                            Mobile Number
                          </TableCell>
                          <TableCell
                            style={{ fontSize: "17px", fontWeight: 600 }}
                          >
                            Communication Number
                          </TableCell>
                          <TableCell
                            style={{ fontSize: "17px", fontWeight: 600 }}
                          >
                            Preferred Hospital & eClinic
                          </TableCell>
                          <TableCell
                            style={{ fontSize: "17px", fontWeight: 600 }}
                          >
                            Concierge Name
                          </TableCell>
                          <TableCell
                            style={{ fontSize: "17px", fontWeight: 600 }}
                          >
                            Concierge Status
                          </TableCell>
                          <TableCell
                            style={{
                              fontSize: "17px",
                              fontWeight: 600,
                              width: "200px",
                            }}
                          >
                            Status
                          </TableCell>
                          <TableCell
                            style={{ fontSize: "17px", fontWeight: 600 }}
                          >
                            Reason For Hold
                          </TableCell>
                          <TableCell
                            style={{ fontSize: "17px", fontWeight: 600 }}
                          >
                            Reason For Reject
                          </TableCell>
                          <TableCell
                            style={{ fontSize: "17px", fontWeight: 600 }}
                          >
                            Status Date
                          </TableCell>
                          <TableCell
                            style={{ fontSize: "17px", fontWeight: 600 }}
                          >
                            View Enquiry Details
                          </TableCell>
                          <TableCell
                            style={{ fontSize: "17px", fontWeight: 600 }}
                          >
                            Billing Count
                          </TableCell>
                          
                          <TableCell
                            style={{ fontSize: "17px", fontWeight: 600 }}
                          >
                            Action
                          </TableCell>
                          <TableCell
                            style={{ fontSize: "17px", fontWeight: 600 }}
                          >
                            Camp
                          </TableCell>
                          <TableCell
                            style={{ fontSize: "17px", fontWeight: 600 }}
                          >
                            Excecutive
                          </TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {paginatedEnquiryDetails.map((notifi) => (
                          <TableRow key={notifi.id}>
                            <TableCell
                              style={{ fontSize: "17px", fontWeight: 500 }}
                            >
                              {notifi.id}
                            </TableCell>
                            <TableCell
                              style={{ fontSize: "15px", fontWeight: 500 }}
                            >
                              {notifi.patientId}
                            </TableCell>
                            <TableCell
                              style={{ fontSize: "15px", fontWeight: 500 }}
                            >
                              {notifi.name}
                            </TableCell>
                            <TableCell
                              style={{ fontSize: "15px", fontWeight: 500 }}
                            >
                              {convertUTCToLocal(notifi.createdDate)}
                            </TableCell>
                            <TableCell
                              style={{ fontSize: "15px", fontWeight: 500 }}
                            >
                              {notifi.chehospitalName || ""}
                            </TableCell>
                            <TableCell
                              style={{ fontSize: "15px", fontWeight: 500 }}
                            >
                              {notifi.chelocationName}
                            </TableCell>
                            <TableCell
                              style={{ fontSize: "15px", fontWeight: 500 }}
                            >
                              {notifi.emailId}
                            </TableCell>
                            <TableCell
                              style={{ fontSize: "15px", fontWeight: 500 }}
                            >
                              {notifi.swasthUserName
                                ? notifi.swasthUserName
                                : "NA"}
                            </TableCell>
                            <TableCell
                              style={{ fontSize: "15px", fontWeight: 500 }}
                            >
                              {notifi.contactNumber ? notifi.contactNumber : ""}
                            </TableCell>
                            <TableCell
                              style={{ fontSize: "15px", fontWeight: 500 }}
                            >
                              {notifi.communicationMobile
                                ? notifi.communicationMobile
                                : ""}
                            </TableCell>
                            <TableCell
                              style={{ fontSize: "15px", fontWeight: 500 }}
                            >
                              {notifi.hospitalName}
                            </TableCell>
                            <TableCell
                              style={{ fontSize: "15px", fontWeight: 500 }}
                            >
                              {notifi.conciergeName || ""}
                            </TableCell>
                            <TableCell
                              style={{ fontSize: "15px", fontWeight: 500 }}
                            >
                              <span
                                style={getconceirgeStyle(
                                  notifi.conciergeStatus
                                )}
                              >
                                {notifi.conciergeStatus == 1
                                  ? "Patient Admitted"
                                  : notifi.conciergeStatus == 2
                                  ? "Patient Denied"
                                  : notifi.conciergeStatus == 3
                                  ? "Patient iN OT"
                                  : notifi.conciergeStatus == 4
                                  ? "Patient Discharged"
                                  : ""}
                              </span>
                            </TableCell>
                            <TableCell
                              style={{
                                fontSize: "15px",
                                fontWeight: 500,
                                width: "200px",
                              }}
                            >
                              {" "}
                              <span style={getStylestatus(notifi.status)}>
                                {notifi.status == 0
                                  ? "Rejected"
                                  : notifi.status == 1
                                  ? "Under Process"
                                  : notifi.status == 6
                                  ? "Procedure Confirmed"
                                  : notifi.status == 5
                                  ? "Hospital Denied"
                                  : notifi.status == 2
                                  ? "Discussing with Hospital"
                                  : notifi.status == 3
                                  ? "Discussing with Patient"
                                  : notifi.status == 4
                                  ? "Patient Denied"
                                  : notifi.status == 12
                                  ? "Waiting For Approval"
                                  : notifi.status == 7
                                  ? "Hold"
                                  : notifi.status == 11
                                  ? "New"
                                  : ""}
                              </span>
                            </TableCell>
                            {notifi.status == 7 ? (
                              <TableCell
                                style={{ fontSize: "15px", fontWeight: 500 }}
                              >
                                {notifi.reasonForHold}
                              </TableCell>
                            ) : (
                              <TableCell></TableCell>
                            )}
                            {notifi.status == 0 ? (
                              <TableCell
                                style={{ fontSize: "15px", fontWeight: 500 }}
                              >
                                {notifi.reasonForReject}
                              </TableCell>
                            ) : (
                              <TableCell></TableCell>
                            )}
                            <TableCell>
                              {notifi?.patientServiceEnquiryFormStatusLogList
                                ? convertUTCToLocal(
                                    notifi
                                      ?.patientServiceEnquiryFormStatusLogList[0]
                                      ?.statusDate
                                  )
                                : ""}
                            </TableCell>
                            <TableCell
                              style={{ fontSize: "15px", fontWeight: 500 }}
                            >
                              <FaEye
                                onClick={(e) => handleClick(notifi, "View")}
                              />
                            </TableCell>

                            <TableCell
                              style={{ fontSize: "15px", fontWeight: 500 }}
                            >

                              <FaEye
                                onClick={() => onCountView(notifi)}
                              />
                            </TableCell>
                           
                            {notifi.status == 6 &&
                            notifi.conciergeStatus == 4 ? (
                              <TableCell
                                style={{
                                  fontSize: "15px",
                                  fontWeight: 500,
                                  width: "10%",
                                }}
                                onClick={(e) => handleClick(notifi, "Add")}
                                disabled={
                                  notifi.billingStatus == 2 ||
                                  roleCode === "ACCADMIN"
                                }
                              >
                                Add Service +
                              </TableCell>
                            ) : (
                              <TableCell> </TableCell>
                            )}
                             <TableCell
                                style={{ fontSize: "15px", fontWeight: 500 }}
                              >
                                 {notifi?.campLead === 0 ? "No" : "Yes"}
                              </TableCell>

                            {notifi.status != 0 && notifi.status != 7 ? (
                              <TableCell>
                                <button
                                  className="w-36 h-9 bg-[#004172] text-white rounded-full"
                                  onClick={(e) =>
                                    handleClick1(notifi, "Assign", "CE")
                                  }
                                >
                                  Executive Assign
                                </button>
                              </TableCell>
                            ) : (
                              <TableCell> </TableCell>
                            )}
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                )}
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    marginTop: "2rem",
                  }}
                >
                  <Pagination
                    count={Math.ceil(
                      paginatedEnquiryDetails[0]?.pagination?.totalRecords /
                        ITEMS_PER_PAGE
                    )}
                    page={page1}
                    color="secondary"
                    onChange={handlePageChange}
                  />
                </Box>
              </div>
            </div>
          </div>
          <Sidebar
            style={{ width: "80%", borderBottom: "1px solid #ccc" }}
            dismissable={false}
            visible={billingView}
            onHide={() => handleCancelView1()}
            position="right"
          >
            <>
              <div>
                {billingData && (
                  <div class="w-118 h-10 top-98 left-47  bg-lightBlue bg-opacity-5.5 overflow-auto">
                    <Typography class="font-poppins text-base font-semibold leading-6 mt-2 ml-2 text-left">
                      Enquiry Id - {billingData[0]?.serviceEnquiryId}
                    </Typography>
                  </div>
                )}
                <div class="w-118 h-10 top-98 left-47  bg-lightBlue bg-opacity-5.5 overflow-auto">
                  <Typography class="font-poppins text-base font-semibold leading-6 mt-2 ml-2 text-left">
                    Total Billing - { billingData?.length >0? billingData[0]?.pagination?.totalRecords : 0}
                  </Typography>
                </div>
                <TableContainer
                  component={Paper}
                  style={{ width: "100%", height: "36vw", overflowX: "auto" }}
                >
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell
                          style={{ fontSize: "15px", fontWeight: 600 }}
                        >
                          Patient ID
                        </TableCell>
                        <TableCell
                          style={{ fontSize: "15px", fontWeight: 600 }}
                        >
                          Patient Name
                        </TableCell>
                        <TableCell
                          style={{ fontSize: "15px", fontWeight: 600 }}
                        >
                          Procedure Name
                        </TableCell>
                        <TableCell
                          style={{ fontSize: "15px", fontWeight: 600 }}
                        >
                          eClinic Name
                        </TableCell>
                        <TableCell
                          style={{ fontSize: "15px", fontWeight: 600 }}
                        >
                          Preffered Hospital & Clinic
                        </TableCell>
                        <TableCell
                          style={{ fontSize: "15px", fontWeight: 600 }}
                        >
                          DoctorName
                        </TableCell>
                        <TableCell
                          style={{ fontSize: "15px", fontWeight: 600 }}
                        >
                          Gross Amount
                        </TableCell>
                        <TableCell
                          style={{ fontSize: "15px", fontWeight: 600 }}
                        >
                          MCI
                        </TableCell>
                        <TableCell
                          style={{ fontSize: "15px", fontWeight: 600 }}
                        >
                          Net Sale
                        </TableCell>
                        <TableCell
                          style={{ fontSize: "15px", fontWeight: 600 }}
                        >
                          Total Amount
                        </TableCell>
                        <TableCell
                          style={{ fontSize: "15px", fontWeight: 600 }}
                        >
                          Billing Status
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {billingData?.map((notifi) => (
                        <TableRow key={notifi.id}>
                          <TableCell
                            style={{ fontSize: "13px", fontWeight: 500 }}
                          >
                            {notifi.patientId}
                          </TableCell>
                          <TableCell
                            style={{ fontSize: "13px", fontWeight: 500 }}
                          >
                            {notifi.patientName}
                          </TableCell>
                          <TableCell
                            style={{ fontSize: "13px", fontWeight: 500 }}
                          >
                            {notifi.procedureName}
                          </TableCell>
                          <TableCell
                            style={{ fontSize: "13px", fontWeight: 500 }}
                          >
                            {notifi.cheCenterName}
                          </TableCell>
                          <TableCell
                            style={{ fontSize: "13px", fontWeight: 500 }}
                          >
                            {notifi.hospitalName}
                          </TableCell>
                          <TableCell
                            style={{ fontSize: "13px", fontWeight: 500 }}
                          >
                            {notifi.referralUserName}
                          </TableCell>
                          <TableCell
                            style={{ fontSize: "13px", fontWeight: 500 }}
                          >
                            {notifi.gross}
                          </TableCell>
                          <TableCell
                            style={{ fontSize: "13px", fontWeight: 500 }}
                          >
                            {notifi.mci}
                          </TableCell>
                          <TableCell
                            style={{ fontSize: "13px", fontWeight: 500 }}
                          >
                            {notifi.yieldAmount}
                          </TableCell>
                          <TableCell
                            style={{ fontSize: "13px", fontWeight: 500 }}
                          >
                            {notifi.totalAmount}
                          </TableCell>
                          <TableCell
                            style={{ fontSize: "15px", fontWeight: 500 }}
                          >
                            <span style={getbillingStyle(notifi.billingStatus)}>
                              {notifi.billingStatus == 1
                                ? "Billing Uploaded"
                                : notifi.billingStatus == 3
                                ? "Billing Completed"
                                : notifi.billingStatus == 2
                                ? "Billing Done"
                                : notifi.billingStatus == 4
                                ? "Billing Approved"
                                : notifi.billingStatus == 5
                                ? "Billing Rejected"
                                : ""}
                            </span>
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
                <Divider />
                <div className="bg-white"></div>
              </div>
            </>
          </Sidebar>
        </>
      </OurLayouts>
    </>
  );
};

export default EnquiryList;
