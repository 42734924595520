import React, { useEffect, useState,useRef } from "react";
import { Dropdown } from "primereact/dropdown";
import { InputText } from "primereact/inputtext";
import { Calendar } from "primereact/calendar";
import apiService from "../../services/apiservice";
import { toast } from "react-toastify";
import moment from "moment";
import { Sidebar } from "primereact/sidebar";
import PatientSearch from "../Shared/patientSearch";

const FilterEnquiry = (props) => {
  const convertUTCDate = (date, format) => {
    format = format || "MM/DD/YYYY"; // YYYY-MM-DD hh:mm:ss
    const localTime = moment.utc(date).toDate();
    return moment(localTime).format(format);
  };
  const inputRef = useRef(null);
  const inputRef2 = useRef(null);


  const [filterObj, setFilterObj] = useState(
    props.filterObj ? props.filterObj : {}
  );
  const [patient, setPatient] = useState({});
  const [hospitalList, setHospitalList] = useState([]);
  const [ceUserList, setCeUserList] = useState([]);
  const [patientSearch, setPatientSearch] = useState(false);

  const serviceList = [
    { id: 8, value: "IPD" },
    { id: 9, value: "OPD" },
    { id: 10, value: "Investigation" },
    { id: 11, value: "Day Care " },
  ];
  const conceirgStatusList = [
    { id: 1, value: "Patient Admitted" },
    { id: 2, value: "Patient Denied" },
    { id: 3, value: "Patient in OT" },
    { id: 4, value: "Patient Discharged" },
  ];
  const statusList = [
    { id: 0, value: "Rejected" },
    { id: 1, value: "Under Process" },
    { id: 4, value: "Patient Denied" },
    { id: 5, value: "Hospital Denied" },
    { id: 6, value: "Procedure Confirmed" },
    { id: 12, value: "Waiting For Approval" },
    { id: 7, value: "Hold" },
  ];
  const campLeadOptions = [
    { id: 1, label: "Yes" },
    { id: 0, label: "No" },
  ];

  const handleChange = (e) => {
    if (e.target?.id !== "date") {
      setFilterObj({
        ...filterObj,
        [e.target.name]: e.target.value,
      });
    } else {
      if (
        (filterObj.fromDate &&
          (e.target.name === "fromDate1" || e.target.name === "toDate1")) ||
        (filterObj.fromDate1 &&
          (e.target.name === "fromDate" || e.target.name === "toDate")) ||
        (filterObj.toDate1 &&
          (e.target.name === "fromDate" || e.target.name === "toDate"))
      ) {
        toast.error("Please clear the conflicting date filter");
        return;
      }
      setFilterObj({
        ...filterObj,
        [e.target.name]: formatDate(e.target.value),
      });
    }
  };

  const handleSelectedData = (e) => {
    let filter = {};
    filter = filterObj || {};
    filter.patientId = e.code;
    filter.patientName = e.name;
    setFilterObj(filter);
    setPatient(e);
  };

  const getConciergeList = async () => {
    let payload = {
      roleCode: "CE",
      status: 1,
    };
    let res = await apiService.getDoctorList2(payload);
    setCeUserList(res.data);
  };

  const handleCancel = () => {
    console.log(filterObj)
inputRef.current.value = '';
inputRef2.current.value = '';


    setFilterObj({});
  };

  const formatDate = (date) => {
    if (!date) return "";

    const d = new Date(date);
    let month = "" + (d.getMonth() + 1),
      day = "" + d.getDate(),
      year = d.getFullYear();

    if (month.length < 2) month = "0" + month;
    if (day.length < 2) day = "0" + day;

    return [month, day, year].join("/");
  };

  const parseDate = (dateString) => {
    if (!dateString) return;
    const [month, day, year] = dateString.split("/");
    return new Date(year, month - 1, day);
  };

  const getHospitalList = async () => {
    let payload = {
      status: 1,
      HospitalType: "H",
    };
    let res = await apiService.getHospitalList(payload);
    setHospitalList(res.data);
  };

  const filterService = () => {
    console.log(filterObj, "FilterObjectClosing");
    props.onClose(filterObj);
    setFilterObj({});
  };

  const getHeader = () => {
    return (
      <div className="font-poppins text-primary text-xl font-bold leading-6 tracking-normal text-left">
        {" Patient Search"}
      </div>
    );
  };

  const toggleSidebar = () => {
    setPatientSearch(!patientSearch);
  };

  useEffect(() => {
    if(!filterObj.fromDate1 && !filterObj.toDate1 ){
    const today = new Date();
    const firstDayOfMonth = new Date(today.getFullYear(), today.getMonth(), 1);
    const lastDayOfMonth = new Date(
      today.getFullYear(),
      today.getMonth() + 1,
      0
    );

    setFilterObj({
      ...filterObj,
      fromDate: formatDate(firstDayOfMonth),
      toDate: formatDate(lastDayOfMonth),
    });
  }
  if(filterObj.fromDate && filterObj.toDate ){
    setFilterObj({
      ...filterObj,
      fromDate: formatDate(filterObj.fromDate),
      toDate: formatDate(filterObj.toDate),
    });
  }
    getConciergeList();
    getHospitalList();
  }, []);

  return (
    <>
      <div className="grid mt-4 grid-cols-2 gap-4">
        <div>
          <label htmlFor="serviceEnquiryId">Enquiry Id</label>
          <div className="relative">
            <input
              id="serviceEnquiryId"
              name="serviceEnquiryId"
              className="border border-gray-300 rounded-md p-2 pr-10 w-full"
              onChange={(e) => handleChange(e)}
              value={filterObj?.serviceEnquiryId}
              placeholder="Search for Enquiry Id"
              type="number"
              pattern="\d*"
              ref={inputRef}              


            />
          </div>
        </div>

        <div>
          <label htmlFor="dob">Enquiry Created From Date</label>
          <Calendar
            id="date"
            className="border border-gray-300 rounded-md p-2 w-full"
            dateFormat="dd/mm/yy"
            style={{ height: "40px" }}
            onChange={(e) => handleChange(e)}
            name="fromDate1"
            value={parseDate(filterObj?.fromDate1)}
            placeholder="Select From Date"
            disabled={!!filterObj.fromDate || !!filterObj.toDate}
          />
        </div>
        <div>
          <label htmlFor="dob">Enquiry Created To Date</label>
          <Calendar
            id="date"
            className="border border-gray-300 rounded-md p-2 w-full"
            dateFormat="dd/mm/yy"
            style={{ height: "40px" }}
            onChange={(e) => handleChange(e)}
            name="toDate1"
            value={parseDate(filterObj?.toDate1)}
            placeholder="Select To Date"
            disabled={!!filterObj.fromDate || !!filterObj.toDate}
          />
        </div>
        <div>
          <label htmlFor="dob">Enquiry Modified From Date</label>
          <Calendar
            id="date"
            className="border border-gray-300 rounded-md p-2 w-full"
            dateFormat="dd/mm/yy"
            style={{ height: "40px" }}
            onChange={(e) => handleChange(e)}
            name="fromDate"
            value={parseDate(filterObj?.fromDate)}
            placeholder="Select From Date"
            disabled={!!filterObj.fromDate1 || !!filterObj.toDate1}
          />
        </div>
        <div>
          <label htmlFor="dob">Enquiry Modified To Date</label>
          <Calendar
            id="date"
            className="border border-gray-300 rounded-md p-2 w-full"
            dateFormat="dd/mm/yy"
            style={{ height: "40px" }}
            onChange={(e) => handleChange(e)}
            name="toDate"
            value={parseDate(filterObj?.toDate)}
            placeholder="Select To Date"
            disabled={!!filterObj.fromDate1 || !!filterObj.toDate1}
          />
        </div>
        <div>
          <label htmlFor="emailId">Patient Name</label>
          <div className="relative">
            <InputText
              id="patientName"
              className="border border-gray-300 rounded-md p-2 pr-10 w-full"
              value={filterObj?.patientName}
              name="patientName"
              placeholder="Search for Patient Name"
              readOnly
              ref={inputRef2}

            />
            <div
              className="absolute inset-y-0 right-0 flex items-center pr-2"
              onClick={toggleSidebar}
            >
              <svg
                className="w-5 h-5 text-gray-400"
                fill="none"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path d="M21 21l-5.2-5.2"></path>
                <circle cx="10" cy="10" r="7"></circle>
              </svg>
            </div>
          </div>
        </div>
        <div>
          <label htmlFor="name">Preferred Hospitals</label>
          <Dropdown
            optionLabel="hospitalName"
            placeholder="Select Preferred Hospitals"
            options={hospitalList}
            name="preferredHospitalAndClinics"
            value={filterObj?.preferredHospitalAndClinics}
            optionValue="code"
            className="border border-gray-300 rounded-md w-full"
            onChange={(e) => handleChange(e)}
            filter
          />
        </div>
        <div>
          <label htmlFor="name">Service Type</label>
          <Dropdown
            optionLabel="value"
            placeholder="Select Service Type"
            options={serviceList}
            name="serviceType"
            value={filterObj?.serviceType}
            optionValue="id"
            className="border border-gray-300 rounded-md w-full"
            onChange={(e) => handleChange(e)}
          />
        </div>
        <div>
          <label htmlFor="dob">Procedure Planned Date</label>
          <Calendar
            id="date"
            className="border border-gray-300 rounded-md p-2 w-full"
            dateFormat="dd/mm/yy"
            style={{ height: "40px" }}
            onChange={(e) => handleChange(e)}
            name="procedurePlannedDate"
            placeholder="Select Procedure Planned date"
            value={parseDate(filterObj?.procedurePlannedDate)}
          />
        </div>
        {props.mode !== "New" && (
          <div>
            <label htmlFor="name">Status</label>
            <Dropdown
              optionLabel="value"
              placeholder="Select Status"
              options={statusList}
              name="status"
              value={filterObj?.status}
              optionValue="id"
              className="border border-gray-300 rounded-md w-full"
              onChange={(e) => handleChange(e)}
            />
          </div>
        )}
        <div>
          <label htmlFor="name">Concierge Executive</label>
          <Dropdown
            value={filterObj?.conciergeUserCode}
            options={ceUserList}
            optionLabel="userName"
            placeholder="Select a Concierge Executive"
            optionValue="code"
            name="conciergeUserCode"
            editable
            className="border border-gray-300 rounded-md w-full"
            onChange={(e) => handleChange(e)}
          />
        </div>
        <div>
          <label htmlFor="name">Concierge Status</label>
          <Dropdown
            value={filterObj?.conciergeStatus}
            options={conceirgStatusList}
            optionLabel="value"
            placeholder="Select a Concierge Status"
            optionValue="id"
            name="conciergeStatus"
            editable
            className="border border-gray-300 rounded-md w-full"
            onChange={(e) => handleChange(e)}
          />
        </div>
        <div>
          <label htmlFor="name">CAMP LEAD</label>
          <Dropdown
            optionLabel="label"
            placeholder="Select Camp lead or Not"
            options={campLeadOptions}
            value={filterObj?.campLead}
            optionValue="id"
            className="border border-gray-300 rounded-md w-full"
            onChange={(e) => handleChange(e)}
            name="campLead" 
            filter
          />
        </div>
      </div>
      <div className="grid mt-36 ml-12 grid-cols-2 gap-4">
        <div>
          <button
            className="h-9 bg-[#004172] text-white rounded-md w-full"
            onClick={filterService}
          >
            Ok
          </button>
        </div>
        <div>
          <button
            className="h-9 border border-[#004172] text-[#004172] font-bold font-monospace rounded-md w-full"
            onClick={handleCancel}
          >
            Clear
          </button>
        </div>
      </div>
      <PatientSearch
        showbar={patientSearch}
        setshowBar={toggleSidebar}
        onSelect={handleSelectedData}
      />
    </>
  );
};

export default FilterEnquiry;
